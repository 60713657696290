const ErrorEffect = () => {
  return (
    <div className="absolute left-[50%] top-[50%] translate-y-[-25%] w-[20vw] h-[20vw] min-w-[150px] min-h-[150px] z-[90]">
      <div className="relative left-[-50%] top-[-50%] w-full h-full">
        <img src="images/giphy.gif" alt="the image" className="" />
        <h1 className="bg-rose-400 text-white px-4 py-1 rounded-full text-[min(3vw,1.5rem)] text-bold absolute z-50 bottom-[-2vw] left-[50%] translate-x-[-50%] w-max text-center font-semibold shadow-2xl">
          Sorry, unexpected server error occurred
        </h1>
      </div>
    </div>
  );
};

export default ErrorEffect;
